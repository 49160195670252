@import 'bootstrap/buttons';

.btn-secondary {
  //@include button-variant(#fff, #90a2a9, #90a2a9);
  @include button-variant(#90a2a9, #90a2a9);
  color: #ffffff;
}

.btn-white {
  border: 1px solid #CBCBCD;
  background: #FFFFFF;
  display: flex;
  padding: 8px 32px;
  font-weight: 400;
  font-size: 16px;
  height: 40px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #4B4B4E;
    margin: auto;
  }

  &.active {
    background: #00A9DC;
    border: 1px solid #00A9DC;

    span {
      font-weight: 600;
      color: #FFFFFF;
    }
  }
}

.btn {
  &:focus {
    box-shadow: none;
  }
}

.btn-primary {
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border-radius: 4px;
  white-space: nowrap;
  text-transform: capitalize;
  background: #E77C46;
  border: 1px solid #E77C46;
  height: 40px;

  &:hover {
    background: #EB8F61;
    border: 1px solid #EB8F61;
    box-shadow: 0px 6px 6px rgba(61, 61, 61, 0.04);
  }

  &:focus {
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    background: #E25F1D;
    border: 1px solid #E25F1D;

    &:focus {
      box-shadow: none;
    }
  }

  &.disabled, &:disabled {
    background: #EAEAEB;
    border: 1px solid #EAEAEB;
    color: #7D7D82;
    cursor: default;
  }
}

.btn-black {
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #323234;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #323234;
  height: 40px;
  
  span {
    margin: auto;
  }

  &:hover {
    background: #4B4B4E;
    border: 1px solid #4B4B4E;
  }

  &:active {
    background: #19191A;;
    border: 1px solid #19191A;
  }

  &.disabled, &:disabled {
    background: #EAEAEB;
    border: 1px solid #EAEAEB;
    color: #7D7D82;
    cursor: default;
  }
}

.btn-secondary {
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #ffffff;
  border: 1px solid #323234;
  border-radius: 4px;   
  color: #19191A;
  height: 40px;
  white-space: nowrap;

  &:hover {
    background: #F7F7F8;
    border: 1px solid #4B4B4E;
    box-shadow: 0px 6px 6px rgba(61, 61, 61, 0.04);
    color: #19191A;
  }

  &:active, &:focus {
    background: #F7F7F8;
    border: 1px solid #19191A;
    color: #19191A;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active {
    background: #F7F7F8;
    border: 1px solid #19191A;
    color: #19191A;

    &:focus {
      box-shadow: none;
    }
  }
}

.btn-secondary-orange {
  padding: 0px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #FFFFFF;
  border: 1px solid #FD4D35;
  border-radius: 4px; 
  color: #FD4D35;
  height: 40px;
  white-space: nowrap;

  &:hover {
    background: #F7F7F8;
    border: 1px solid #FD6854;
    box-shadow: 0px 6px 6px rgba(61, 61, 61, 0.04);
    color: #FD6854;
  }

  &:active {
    background: #F7F7F8;
    border: 1px solid #FC2003;
    color: #FC2003;
  }
}

.btn-load-more {
  height: 40px;
  border: 1px solid #323234;
  background: #FFFFFF;
  padding: 12px 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover, &:focus {
    background: #F7F7F8;
    border: 1px solid #4B4B4E;
    box-shadow: 0px 6px 6px rgba(61, 61, 61, 0.04);
    border-radius: 4px;
  }

  &:focus {
    border: 1px solid #19191A;
  }
}

.btn-dark-grey {
  justify-content: center;
  align-items: center;
  padding: 11px 32px 12px;
  height: 40px;
  border-radius: 0;
  background: #404040;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #ffffff;
}

.btn-dark-grey:hover {
  color: #ffffff;
}

.btn-group {
  width: auto;
  .btn-radio-group {
    width: auto;
    border: none;
    background: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .btn-radio-group.active {
    color: #e98436 !important;
    background-color: none !important;
  }
}

.btn {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 1500px) {
    padding: 0px 16px !important;
  }
}
