/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */

@import 'variables';
@import 'fonts';
@import 'bootstrap/bootstrap';
@import 'ot_card';
@import 'ot_button';
@import 'ot_modal';
@import 'ot_table';
@import 'icons';
@import 'progress';
@import 'utility';
@import '~ng-busy/src/style/busy.css';
@import '~sweetalert2/src/sweetalert2';
@import '~ladda/css/ladda';
html {
  font-size: 14px;
}

.modal .close {
  cursor: pointer;
}

input[type='text']::-ms-reveal,
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.container-fluid {
  margin: 0;
  padding-right: 24px;
  padding-left: 24px;
}

.ng-busy-backdrop {
  opacity: 0.9;
}

.ot-preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 2rem;
  span {
    margin-top: 1rem;
    font-size: 1.4rem;
  }
}

.btn {
  cursor: pointer;

  &.btn-link {
    padding: 0;
    margin: 0.5rem 1rem;

    &:focus {
      box-shadow: none;
    }

    &.ladda-button[data-style='expand-right'][data-loading] {
      padding-right: 2rem;
      margin-right: 0;
    }

    > .ladda-spinner {
      right: -15px;

      > div > div > div {
        background: #636c72 !important;
      }
    }

    &.btn-link--danger {
      color: #df4700;

      &:hover {
        color: #ac3700;
      }

      &:disabled {
        color: #ac7c5a;
      }

      > .ladda-spinner > div > div > div {
        background: #ac7c5a !important;
      }
    }

    &--inline {
      padding: 0;
      margin: 0;
    }
  }

  span {
    margin: auto;
  }
}

.form-control::placeholder {
  color: #b1b1b4;
}

.required-mark {
  color: #e03636;
}

.form-control {
  border-radius: 4px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid $grey-25;
  padding: 8px 26px 8px 16px;
  color: #19191a;
  &.search {
    background: url('~apps/admin/src/assets/svg/search.svg') no-repeat #fff 10px
      center;
    padding-left: 40px;
    background-size: 16px;
  }
}

ngb-datepicker-navigation {
  .btn-link {
    border: none !important;
    height: auto !important;
  }
}
.popover {
  &-body {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4b4b4e;

    @media (max-width: 767px) {
      padding: 24px;
    }
  }
}

.agency-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 8px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-down('sm') {
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  &--title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #19191a;
    text-transform: uppercase;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include media-breakpoint-down('sm') {
      text-align: center;
      display: flex;
    }
  }
  &--links {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down('sm') {
      overflow: auto;
      align-items: center;
      width: 100%;
    }

    > * {
      margin-left: 1rem;

      @include media-breakpoint-down('sm') {
        margin-left: 0;
      }
    }

    a {
      vertical-align: text-bottom;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $blue-60;
      @include media-breakpoint-down('sm') {
        display: inline-block;
        margin-left: 0;
      }

      &.active {
        font-weight: 400;
        color: #19191a;
      }
      .badge {
        min-width: 1.885rem;
        border-radius: 2px;
        background: #fd6854;
        text-align: center;
        color: #ffffff;
        font-size: 14px;
        line-height: 10px;
        font-weight: 500;
        margin-left: 4px;
      }
    }
  }
  &--img {
    flex: 1 1 auto;
  }
  &--left {
    margin-top: auto;
    margin-bottom: auto;

    @include media-breakpoint-down('sm') {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.search-dropdown {
  width: 31.143rem;
  overflow-y: auto;
  max-height: 26.5rem;
  padding: 11px;
  .dropdown-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    cursor: pointer;
    @media (max-width: 1500px) {
      font-size: 13px;
    }
  }
  .highlight {
    background: rgba(0, 159, 222, 0.7);
  }
  .search-title {
    background: #d8e2e6;
    color: #000000;
    font-weight: bold;
    cursor: auto;
  }
}

.agency-content {
  margin-bottom: 1rem;
  background: #ffffff;
  // padding: 15px;
  &--footer {
    margin: 32px -15px -15px;
    // padding: 15px;
  }
}

.btn-group > label.btn > input[type='radio'] {
  display: none;
}

.ot-column-commission_received,
.ot-column-agent_paid,
.ot-column-expandedAgent {
  overflow: initial !important;
}

.ngx-charts .circle,
.ngx-charts .bar,
.ngx-charts .arc {
  cursor: initial !important;
}

.c-pointer {
  cursor: pointer;
}

.c-default {
  cursor: default !important;
}

.c-block {
  cursor: not-allowed !important;
}

button.dropdown-item {
  cursor: pointer;
}

button.close {
  cursor: pointer !important;
}

ngb-datepicker {
  .btn-link {
    margin: 0 !important;
  }
}

.lh-1 {
  line-height: 1;
}

input + .input-group-addon {
  background-color: #fff;
  border: 1px solid #ced4da;
  color: #292b2c;
  border-left: 0;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.popover {
  &-body {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.fgr-idle-modal {
  .modal-content {
    max-width: 540px;
    border-radius: 0;
    border: none;
  }
}

.form-group {
  margin-bottom: 12px;
}

label {
  margin-bottom: 6px;
}
ot-data-table {
  a {
    font-weight: 500;
  }
}

ot-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #323234;
}

.dropdown-menu {
  border-radius: 4px;
  color: #201f1f;
  font-weight: 500;
  font-size: 15px;
  .dropdown-item {
    min-height: 40px;
    overflow-y: hidden;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #4b4b4e;
  }

  .dropdown-item:hover {
    background-color: #f1f1f1;
    color: #201f1f;
  }
}

.btn-group {
  label {
    display: flex;
    * {
      margin: auto;
    }
  }
}

.ngx-charts-tooltip-content.type-tooltip {
  color: #201f1f !important;
  background: #fff !important;
  border-radius: 0 !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);
}

.ngx-charts-tooltip-content {
  padding: 24px !important;
  .tooltip-title {
    margin-bottom: 10px;
  }
  .tooltip-caret.position-top {
    border-left: 15px solid transparent !important;
    border-right: 15px solid transparent !important;
    border-top: 20px solid #fff !important;
    margin-top: -5px;
  }
  .tooltip-caret.position-bottom {
    border-left: 15px solid transparent !important;
    border-right: 15px solid transparent !important;
    border-bottom: 20px solid #fff !important;
    margin-top: -10px;
  }
}

.mobile-table {
  &__wrapper {
    border-bottom: 1px solid #cbcbcd;
  }

  &__title {
    background: #4b4b4e;
    padding: 16px;
    width: 100%;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    .sort-header-container {
      height: 24px;
    }
  }

  &__card {
    padding: 16px 15px 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    &-title {
      color: $blue-60;
      margin-bottom: 8px;
      display: inline-block;
    }

    &-details {
      p {
        color: #7d7d82;
        margin-bottom: 0;
        padding-bottom: 8px;
      }

      span {
        color: #19191a;
      }
    }

    .dropdown-toggle:after {
      display: none;
    }

    .mobile-btn {
      white-space: nowrap;
      display: flex;
      align-items: center;

      &:focus {
        box-shadow: none;
      }

      &.delete-btn {
        color: #fd6854;
      }
    }

    .mobile-icon {
      margin: 0;
    }

    .mobile-details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }
  }
}

.mobile-dropdown-content.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .upload-doc {
    color: $blue-60;
  }

  .mobile-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &.delete-btn {
      color: #fd6854;
    }
  }

  .mobile-icon {
    svg {
      width: 16px;
    }
  }
}

.sort-icon {
  span {
    color: #7d7d82;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
  }

  .rotate svg {
    transform: rotate(180deg);
    transition: 1s;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-menu {
    padding: 0;
    margin: 0;
  }

  .dropdown-item {
    color: #19191a;
    padding: 8px 16px;

    &.checked {
      background: #f7f7f8;
      font-weight: 600;
    }
  }
}

#freshworks-frame-wrapper {
  top: 10px;
}

ngb-typeahead-window {
  max-height: 285px;
  overflow: auto;
}

@include media-breakpoint-down('sm') {
  .container-fluid,
  .row,
  .col-12,
  .col-6,
  .col-sm-4,
  .col-sm-8 {
    margin: 0;
    padding: 0;
  }

  .modal-content {
    padding: 24px;
  }
}

@media (min-width: 768px) {
  #freshworks-container #freshworks-frame-wrapper {
    width: 330px !important;
    min-height: 700px !important;
  }
}
