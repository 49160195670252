.icon-remove,
.icon-close {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}

.icon-download {
  cursor: pointer;
  svg > * {
    fill: #000;
  }
}

.icon-edit {
  svg > * {
    fill: #6b6b6b;
  }
}

.icon-close {
  * {
    fill: #adadad;
    stroke: #adadad;
  }
}

.icon-24 {
  svg {
    height: 24px;
    width: 24px;
  }

  &.correct-spaces {
    svg {
      margin-bottom: -3px;
    }
  }
}

.icon-32 {
  svg {
    height: 32px;
    width: 32px;
  }
}

.icon-black {
  path {
    fill: #000 !important;
  }
}

.icon-warning {
  path {
    fill: $brand-warning !important;
  }
}
