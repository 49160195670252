@import 'variables';

.ot-table {
  width: 100%;
  @include media-breakpoint-down('sm') {
    width: 600px;
  }
  thead {
    th {
      background: #4B4B4E;
      padding: 16px;
      color: #ffffff;

      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
  tbody {
    tr {
      td {
        background: #ffffff;
        padding: 16px;
        word-break: break-word;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #19191A;
      }
      &:nth-child(2n) {
        td {
          background: #F7F7F8;
        }
      }
      div {
        word-break: break-word;
      }
    }
  }
}
