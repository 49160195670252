@font-face {
  font-family: "Segoe UI";
  // Regular
  src: url("/assets/fonts/SegoeUIRegular/SegoeUIRegular.eot");
  src: url("/assets/fonts/SegoeUIRegular/SegoeUIRegular.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/SegoeUIRegular/SegoeUIRegular.woff") format("woff"),
  url("/assets/fonts/SegoeUIRegular/SegoeUIRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Segoe UI";
  // Bold
  src: url("/assets/fonts/SegoeUIBold/SegoeUIBold.eot");
  src: url("/assets/fonts/SegoeUIBold/SegoeUIBold.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/SegoeUIBold/SegoeUIBold.woff") format("woff"),
  url("/assets/fonts/SegoeUIBold/SegoeUIBold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Segoe UI";
  // Italic
  src: url("/assets/fonts/SegoeUIItalic/SegoeUIItalic.eot");
  src: url("/assets/fonts/SegoeUIItalic/SegoeUIItalic.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/SegoeUIItalic/SegoeUIItalic.woff") format("woff"),
  url("/assets/fonts/SegoeUIItalic/SegoeUIItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: "Segoe UI";
  // Light
  src: url("/assets/fonts/SegoeUILight/SegoeUILight.eot");
  src: url("/assets/fonts/SegoeUILight/SegoeUILight.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/SegoeUILight/SegoeUILight.woff") format("woff"),
  url("/assets/fonts/SegoeUILight/SegoeUILight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Segoe UI";
  // SemiBold
  src: url("/assets/fonts/SegoeUISemiBold/SegoeUISemiBold.eot");
  src: url("/assets/fonts/SegoeUISemiBold/SegoeUISemiBold.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/SegoeUISemiBold/SegoeUISemiBold.woff") format("woff"),
  url("/assets/fonts/SegoeUISemiBold/SegoeUISemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
