//@import "variables";
//@import "apps/admin/src/styles/bootstrap/mixins";

.card-application {

  background-color: #d9e3e7;
  border-color: #dedfdf;

  .card-header,
  .card-footer {
    background-color: transparent;
  }

  border-radius: 0;
  margin-bottom: 1em;
  .card-header {
    border-color: #ebf1f3;
  }
}
